@import '@angular/material/theming';

@mixin notification-bell-component-theme($theme)
{
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);

    .newmessagebell
    {
      .disconnected
      {
        color: mat-color($primary, 300) !important;
      }
      .highlighted
      {
          color: mat-color($accent) !important;
      }
    }
}
