@import '@angular/material/theming';

@mixin identity-component-theme($theme) {

    $primary: map-get($theme, primary);
    $accent:  map-get($theme, accent);


    .identity {
        .identity-header {
            .user {
                .company-role {
                    color: mat-color($accent);
                }
            }
        }
    }
}