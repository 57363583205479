@import '@angular/material/theming';

@mixin class-selector-component-theme($theme) {
    $primary: map-get($theme, primary);
    $accent:  map-get($theme, accent);
    $warning: map-get($theme, warning);
    $background: map-get(map-get($theme, background), md-rules-bg);

    .class-selector {

        .header-documents {

            .selector-name {
                color: mat-color($primary, 700);
            }   
        
        }    
    }

    .add-button {
        color: mat-color($primary, 700);
        &:hover
        {
            .add, .add-icon {
                color: mat-color($accent);
            }
        }

    }

    .class-selector-panel {
        .class-filter-header {
            border-bottom: 1px solid mat-color($primary, 700);
    
            .close-header {
                background-color: $background;
                .close-icon {
                    color: mat-color($primary, 700) !important;
                }
            }
        
            .text-input {
                border-bottom: 1px solid mat-color($primary, 700);
                .class-search-input {
                    color: mat-color($primary, 800) !important;
                    background: transparent;
                }
                .actions {
                    .action-icon {
                        color: mat-color($primary, 800) !important;
                    }
                }
            }
        }
        
        .class-filter-body {

            .menu-firstoptions {
                color: mat-color($primary, 800);
            }
        
            .option .mat-option-text {
                .mat-icon {
                    // color: $gray-md;
                    &:hover {
                        color: mat-color($accent);
                    }
                }
            }
    
            .no-results {
                color: mat-color($primary, 800);
            }
        }
    }
}
