@import '@angular/material/theming';

@mixin save-document-popup-component-theme($theme)
{
    $primary: map-get($theme, primary);
    $accent:  map-get($theme, accent);

    .save-document-popup
    {
        .save-option
        {
            .content
            {
                border-top: 1px solid mat-color($primary, 500);
                .info
                {
                    background-color: mat-color($primary, 400);
                    color: mat-color($primary, A100);
                }
            }
        }
    }
}