@import '@angular/material/theming';

@mixin login-component-theme($theme) {

    $primary: map-get($theme, primary);
    $accent:  map-get($theme, accent);


    .login {
        .resetpass {
            color: mat-color($primary, 900) !important;
        }

        .oauth {
            .text {
                color: mat-color($primary, 900) !important;
            }
            .actions {
            }
        }
    }

   
}
