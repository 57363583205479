@import '@angular/material/theming';

@mixin task-description-popup-component-theme($theme) {

    $primary: map-get($theme, primary);
    $accent:  map-get($theme, accent);

    
    .task-description {

        .description-header {
            .md-popup-header--title {
                color: mat-color($accent); 
            }
        }

        .description-content {

            .task-details,
            .task-documents {
                .title {
                    color: mat-color($primary, 700);
                }
            }
            
            .task-documents {

                .documents {
        
                    .selected-subtask {

                        .line {
                            border-bottom: 1px solid mat-color($primary, 700);
                            
                            .subtask-name {
                                color: mat-color($accent); 
                            }   

                        }
                    }
                }
            }

        }


    }
     

}