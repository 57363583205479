@import '@angular/material/theming';

@mixin post-save-popup-component-theme($theme) {
    
    $primary: map-get($theme, primary);
    $accent:  map-get($theme, accent);

    .radio-group-label
    {
        color: mat-color($primary, 700);
    }
}