@import '@angular/material/theming';

@mixin download-documents-component-theme($theme) {
    
    $primary: map-get($theme, primary);
    $accent:  map-get($theme, accent);

    $md-share-formatbtn-bg: map-get(map-get($theme, background), md-share-formatbtn-bg);
    $md-share-formatbtn-text: map-get(map-get($theme, foreground), md-share-formatbtn-text);

    $md-share-closebtn-bghover: map-get(map-get($theme, background), md-share-closebtn-bghover);
    $md-share-closebtn-texthover: map-get(map-get($theme, foreground), md-share-closebtn-texthover);
  
    .share {

        .share-header {
            .title {
                .mat-icon {
                    color: mat-color($primary, 900);
                }
            }
        }

        .share-content {
            .subtitle {
                color: mat-color($primary, 700);
            }
            .package {
                color: mat-color($primary, 700);
                border-bottom: 1px solid mat-color($primary, 600);
            }
            .place {
                color: mat-color($primary, 700);
            }
        }

        .share-utilities {
            .error {
                border: 2px solid mat-color($primary, 900);
                .mat-icon {
                    color: red !important;
                    cursor: default;
                }
            }
        }
        
        .share-actions {
            .options {
                .download {
                    .mat-icon {
                        color: mat-color($primary, 900) !important;
                    }
                }
            }
        }

        .share-feedback {
            background-color: mat-color($accent);
            .drawing {
                .mat-icon {
                    color: mat-color($accent, 900) !important;
                }
            } 
            .md-button:hover {
                color: $md-share-closebtn-bghover !important;
                border: 2px solid $md-share-closebtn-bghover !important;
                background: none !important;
            }   
        }

        & > button:hover {
            background-color: $md-share-closebtn-bghover;
            border-color: $md-share-closebtn-bghover;
            color: $md-share-closebtn-texthover;
        }

    }  

}