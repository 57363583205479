@import '@angular/material/theming';

@mixin new-version-dialog-component-theme($theme) {
    
    $primary: map-get($theme, primary);
    $accent:  map-get($theme, accent);

    .action:hover {
        color: mat-color($accent);
        .mat-icon 
        {
            color: mat-color($accent);
        }
    }
}