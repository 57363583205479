@import "@angular/material/theming";

@mixin accept-legals-component-theme($theme) {
  $primary: map-get($theme, primary);

  .acceptlegals{
    .actions {
      .okButton:disabled {
        background-color: mat-color($primary) !important;
      }
    }
  }
}
