@import '@angular/material/theming';

@mixin fonts-handler-dialog-component-theme($theme) {
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $bg: map-get($theme, background);
    $list-button-border: map-get(map-get($theme, list), button-border);

    .fonts-handler-dialog
    {
        .fonts-handler-body
        {
            .missing-fonts-list {
                border: 1px solid mat-color($primary, 600);
                .missing-fonts-title {
                    background: mat-color($bg, dialog);
                }
                .font {
                    .font-name
                    {
                        .name {
                            .text {
                                &.not-found {
                                    color: mat-color($primary, 700) !important;
                                }
                            }
                        }
                    }
                }
                .font:hover {
                    background-color: mat-color($bg, background);
                }
            }
            .info {
                .link-button {
                    color: mat-color($accent)
                }
            }
        }
    }
}


