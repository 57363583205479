@import '@angular/material/theming';

@mixin paste-content-popup-component-theme($theme) {
    $primary: map-get($theme, primary);
    $accent:  map-get($theme, accent);

    .font-selection-panel {
        .font-selection-panel-header {
            border-bottom: 1px solid mat-color($primary, 700);
            .text-input {
                .font-search {
                    color: mat-color($primary, 800) !important;
                    background: transparent;
                }
                .actions {
                    .action-icon {
                        color: mat-color($primary, 800) !important;
                    }
                }
            }
        }
        .font-selection-panel-body {    
            .no-results {
                color: mat-color($primary, 800);
            }
        }
    }
}
