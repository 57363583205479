@import '@angular/material/theming';

@mixin library-card-component-theme($theme) {

    $primary: map-get($theme, primary);
    $accent:  map-get($theme, accent);

    $list-general-border: map-get(map-get($theme, list), general-border);
    $list-button-border: map-get(map-get($theme, list), button-border);
    $list-card-background: map-get(map-get($theme, list), card-background);
    $list-card-text: map-get(map-get($theme, list), card-text);
    $list-card-icon-background: map-get(map-get($theme, list), card-icon-background);
    $list-card-icon: map-get(map-get($theme, list), card-icon);

    .library-card
    {
        .action:hover {
            color: mat-color($accent);
            .mat-icon {
                color: mat-color($accent);
            }
        }
        .disabled {
            color: mat-color($primary, 700) !important;
        }
        .filetype {
            background-color: mat-color($primary, 100);
        }
        &.list-view
        {
            background: $list-card-background;
            border: 1px solid $list-general-border;
            color: $list-card-text;
            .resource-icon
            {
                background-color: $list-card-icon-background;
                .mat-icon
                {
                    color: #FAFAFA;
                }
                .char-icon
                {
                    color: #FAFAFA;
                }
            }
            .resource-name
            {
                color: $list-card-text;
            }
            .actions
            {
                .action-element
                {
                    .menu-button
                    {
                        background: $list-card-icon-background;
                        .mat-icon
                        {
                            color: #FAFAFA !important;
                        }
                        .mat-icon:hover
                        {
                            color: #5f9cf8 !important;
                        }
                    }
                    .action-button
                    {
                        border: 1px solid $list-button-border;
                        .mat-icon
                        {
                            color: $list-card-icon !important;
                        }
                    }
                    .tag-button
                    {
                        color: $list-card-icon-background;
                        .mat-icon
                        {
                            color: $list-card-icon !important;
                        }
                    }
                    .action-button:hover, .tag-button:hover
                    {
                        color: #5f9cf8 !important;
                        .mat-icon
                        {
                            color: #5f9cf8 !important;
                        }
                    }
                }
            }
        }

        &.grid-view
        {
            background-color: $list-card-background;
            border: 1px solid $list-general-border;
            .preview
            {
                background-color: #FAFAFA;
                .font-preview
                {
                    color: #E6E6E6;
                    background-color: #5F7280;
                }
            }
            .card-footer
            {
                .footer-element
                {
                    .resource-name
                    {
                        color: $list-card-text;
                    }
                    .menu-button:hover
                    {
                        color: #5f9cf8 !important;
                        .mat-icon
                        {
                            color: #5f9cf8 !important;
                        }
                    }
                    .actions
                    {
                        .action-element
                        {
                            .action-button
                            {
                                color: $list-card-icon;
                                .mat-icon
                                {
                                    color: $list-card-icon !important;
                                }
                            }
                            .action-button:hover
                            {
                                color: #5f9cf8 !important;
                                .mat-icon
                                {
                                    color: #5f9cf8 !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    ::ng-deep .tags-tooltip
    {
        .label 
        {
            border-bottom: 1px solid #FAFAFA;
        }
        .element {
            &.not-available 
            {
                color: #FAFAFA;
            }
        }
        .no-element 
        {
            color: #9e9d9d;
        }
    }

}
