@import '@angular/material/theming';

@mixin tag-list-component-theme($theme) {
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    .taglist {
        .tags {
            input {
                color: mat-color($primary);
            }
        }
    }
}
