@import '@angular/material/theming';

@mixin welcome-component-theme($theme) {
    
    $primary: map-get($theme, primary);
    $accent:  map-get($theme, accent);


    .welcome {
        color: mat-color($primary, 700);

        .link {
            color: mat-color($primary, 900);
            &:hover {
                color: mat-color($accent);
            }
        }
        
    }

}