@import '@angular/material/theming';

@mixin tooltip-component-theme($theme) {

    $primary: map-get($theme, primary);
    $accent:  map-get($theme, accent);

    $md-tooltip-bg:   map-get(map-get($theme, background), md-tooltip-bg);
    $md-tooltip-text: map-get(map-get($theme, foreground), md-tooltip-text);

    .moon-tooltip {
      .box {
        background: $md-tooltip-bg;
        color: $md-tooltip-text;
      }
    }
}
