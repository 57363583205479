@import '@angular/material/theming';

@mixin bulk-import-component-theme($theme) {

    $primary: map-get($theme, primary);
    $accent:  map-get($theme, accent);

    
    .bulk-import {

        .files {
            .selectiontext {
                color: mat-color($accent);
            }
            .status {
                color: mat-color($primary, 700);
                .file-error {
                    color: red !important;
                }
            }
        }

    }

}
