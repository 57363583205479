@import '@angular/material/theming';

@mixin rule-create-component-theme($theme) {
    
    $primary: map-get($theme, primary);
    $accent:  map-get($theme, accent);
    $md-rules-border: map-get(map-get($theme, background), md-rules-border);
    $md-rules-bg: map-get(map-get($theme, background), md-rules-bg);
    $md-rules-card-bg: map-get(map-get($theme, background), md-rules-card-bg);
    $md-rules-logical-op-bg: map-get(map-get($theme, background), md-rules-logical-op-bg);
    $md-rules-icon: map-get(map-get($theme, foreground), md-rules-icon);
    $md-rules-icon-disabled: map-get(map-get($theme, foreground), md-rules-icon-disabled);

    .rule-create {
        .rule-content {
            .rule-configs {
                .rule-config {
                    border-color: mat-color($primary, 500);// $md-rules-border;
                    .doc-config {
                        .doc-types, 
                        .class-values {
                            .card { 
                                &.logical-op {
                                    background-color: $md-rules-logical-op-bg;
                                }
                                &.item {
                                    background-color: $md-rules-card-bg;
                                    border-color: mat-color($primary, 300);
                                }
                            }
                            .add-button {
                                .mat-icon {
                                    color: $md-rules-icon;
                                }
                            }
                        }
                        .doc-types {
                            border-bottom-color: $md-rules-border;
                        }
                    }

                    .text-config {
                        .content-input {
                            background-color: white;
                            color: #262626;
                        }

                    }
                }
                .rule-details {
                    .field {
                        .size-input {
                            background-color: $md-rules-card-bg;
                        }
                    }
                }
            }
        }
        .footer {
            border-top-color: $md-rules-border;
            &.disabled {
                color: $md-rules-icon-disabled;
            }
        }
    }

    .doc-types-finder,
    .class-values-finder {
        background-color: $md-rules-card-bg;
        color: mat-color($primary, 900);
    }
}