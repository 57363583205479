@import '@angular/material/theming';

@mixin work-component-theme($theme) {
    
    $primary: map-get($theme, primary);
    $accent:  map-get($theme, accent);

    .no-document {
        .action:hover {
            color: mat-color($accent);
            .mat-icon 
            {
                color: mat-color($accent);
            }
        }
    }

    .mat-divider {
        border: 1px solid mat-color($primary, 200)
    }
}