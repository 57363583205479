@import '@angular/material/theming';

@mixin sidenav-content-component-theme($theme) {

    $primary: map-get($theme, primary);
    $accent:  map-get($theme, accent);


    .sidenav-content {

        .sidenav-close {
            .mat-icon {
                color: mat-color($primary, 700) !important;
            }
        }

        .sidenav-icon-menu, .sidenav-text-menu {
            .selected {
                color: mat-color($accent) !important;
            }
        }

        .sidenav-text-menu {

            button:hover {
                color: mat-color($accent);
            }
        }
    }
}
