@import '@angular/material/theming';

@mixin frame-component-theme($theme) {

    $primary: map-get($theme, primary);
    $accent:  map-get($theme, accent);

    $md-appbar-tab-hover:    map-get(map-get($theme, background), md-appbar-tab-hover);
    $md-appbar-tab-active:   map-get(map-get($theme, background), md-appbar-tab-active);
    $md-appbar-tab-inactive: map-get(map-get($theme, foreground), md-appbar-tab-inactive);

    .frame {

        .sidenav {
            .mat-icon {
                color: mat-color($primary, 900) !important;
            }
            .selected {
                color: mat-color($accent);
            }
        }

        .appbar {
            background: mat-color($primary, 200);
            
            .appbar-tabs {
                .tab {
                    color: $md-appbar-tab-inactive;
                    &.hover {
                        background: $md-appbar-tab-hover;
                        border: 1px solid mat-color($primary, 200);
                    }
                    &.active {
                        color: mat-color($primary, 900);
                        background: $md-appbar-tab-active;
                    }
                }
            }

            .appbar-icons {
                .mat-icon {
                    color: mat-color($primary, 700);
                    &:hover {
                        color: mat-color($primary, 900);
                    }
                    &.active {
                        color: mat-color($accent) !important;
                        background: $md-appbar-tab-active;
                    }
                }
            }
            
        }

        .content {
            .link-button {
                color: mat-color($accent)
            }
        }

    }

}
