@import '@angular/material/theming';

@mixin find-replace-component-theme($theme) {

    $primary: map-get($theme, primary);
    $accent:  map-get($theme, accent);

    .findreplace {
        color: mat-color($primary, 900);
        // .mat-input-element {
        //     border-bottom: 1px solid mat-color($accent) !important;
        // }
    }

}
